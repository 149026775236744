import { render, staticRenderFns } from "./LivePopularity.vue?vue&type=template&id=760307a1&scoped=true&"
import script from "./LivePopularity.vue?vue&type=script&lang=js&"
export * from "./LivePopularity.vue?vue&type=script&lang=js&"
import style0 from "./LivePopularity.vue?vue&type=style&index=0&id=760307a1&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "760307a1",
  null
  
)

export default component.exports