<template>
    <div class="live-popularity">
        <div class="time-box">
            <div class="item left-train-end-time">
                <div class="icon iconfont">&#xe603;</div>
                <div class="hint-box">
                    <div class="hint">{{ trainHintText }}</div>
                    <div class="time">
                        <span>{{ trainTime[0] | getTimeStr }}:</span>
                        <span>{{ trainTime[1] | getTimeStr }}:</span>
                        <span>{{ trainTime[2] | getTimeStr }}</span>
                    </div>
                </div>
            </div>
            <div class="item right-time-box">
                <template class="not-start-live-duration" v-if="liveState == 0">
                    <div class="icon iconfont">&#xe602;</div>
                    <div class="hint-box">
                        <div class="hint">直播时长</div>
                        <div class="not-start-time time">{{ liveTime | liveTimeFormat(getTime) }}</div>
                    </div>
                </template>
                <template class="live-end" v-else>
                    <div class="icon iconfont">&#xe603;</div>
                    <div class="hint-box">
                        <div class="hint">距离直播结束</div>
                        <div class="time">
                            <span>{{ liveLongEnd[0] | getTimeStr }}:</span>
                            <span>{{ liveLongEnd[1] | getTimeStr }}:</span>
                            <span>{{ liveLongEnd[2] | getTimeStr }}</span>
                        </div>
                    </div>
                </template>
            </div>
        </div>
        <div class="popularity">
            <div class="icon iconfont">&#xe600;</div>
            <div class="right-popularity">
                <div class="hint">人气数据</div>
                <div class="info-list">
                    <div class="item">
                        <div class="left">
                            <div class="label">实时在线人数</div>
                            <div class="num-box">{{ data.onlineTotalNum | carryBit(liveState) }}</div>
                        </div>
                        <div class="right">
                            <div class="label">成交件数</div>
                            <div class="num-box">{{ data.dealNum | carryBit(liveState) }}</div>
                        </div>
                    </div>
                    <div class="item">
                        <div class="left">
                            <div class="label">进入直播间人数</div>
                            <div class="num-box">
                                <div class="num">{{ data.inOnlineNum | carryBit(liveState) }}</div>
                                <div class="rise iconfont">&#xe622;</div>
                            </div>
                        </div>
                        <div class="right">
                            <div class="label">点赞数</div>
                            <div class="num-box">{{ data.like | carryBit(liveState) }}</div>
                        </div>
                    </div>
                    <div class="item">
                        <div class="left">
                            <div class="label">离开直播间人数</div>
                            <div class="num-box">
                                <div class="num">{{ data.outOnlineNum | carryBit(liveState) }}</div>
                                <div class="go-down iconfont">&#xe630;</div>
                            </div>
                        </div>
                        <div class="right">
                            <div class="label">问题数</div>
                            <div class="num-box">{{ questionList[jumpIndex] }}</div>
                        </div>
                    </div>
                    <div class="item">
                        <div class="left">
                            <div class="label">评论数</div>
                            <div class="num-box">{{ commentList[jumpIndex] }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "LivePopularity",
    data() {
        return {
            trainTimer: void 0,
            liveTimer: void 0,
            trainTime: [0, 0, 0],
            liveDuration: 0,
            questionList: [0, 2, 5, 8, 12, 17, 19, 23, 29, 33, 36, 40],
            commentList: [0, 15, 31, 49, 66, 83, 102, 122, 143, 168, 189, 217],
            jumpIndex: 0,
            jumpTimer: void 0
        };
    },
    props: {
        data: {
            type: Object,
            default: () => {},
        },
        liveState: {
            type: Number,
            default: 0,
        },
        liveTime: {
            type: Object,
            default: () => null,
        },
    },
    computed: {
        trainIsStart() {
            let startTime = localStorage.getItem("start_time");
            startTime = startTime ? +new Date(startTime) : +new Date(0);
            let currentTime = +new Date();
            return currentTime > startTime;
        },
        trainHintText() {
            return this.trainIsStart ? "距离实训结束" : "距离实训开始";
        },
        liveLongEnd() {
            return this.getTime(this.liveDuration);
        },
    },
    components: {},
    methods: {
        init() {
            this.setTrainCountdown();
            this.$bus.$on("liveingReload", (currentScript) => {
                this.$nextTick(() => (this.liveDuration = this.liveTime.total_time.total_time - currentScript.startTime));
            });
        },
        getTime(totalSecond) {
            const times = [];
            let hour = Math.floor(totalSecond / 60 / 60);
            let minute = Math.floor(totalSecond / 60) % 60;
            let second = totalSecond % 60;
            return [hour, minute, second];
        },
        setTrainCountdown() {
            let startTime = localStorage.getItem("start_time");
            let endTime = localStorage.getItem("end_time");
            const errorFun = () => {
                this.$message.error("未获得实训时间");
                this.$router.back();
                return void 0;
            };
            if (!startTime || !endTime) errorFun();
            this.trainTimer && clearInterval(this.trainTimer);
            this.trainTimer = setInterval(() => {
                let afterTime = this.trainIsStart ? endTime : startTime;
                const diffSecond = Math.floor((+new Date(afterTime) - +new Date()) / 1000);
                this.trainTime = this.getTime(diffSecond);
                if (this.trainTime.every((item) => !item)) {
                    clearInterval(this.trainTimer);
                    this.$bus.$emit("countdownEnd");
                }
            }, 1000);
        },
        setLiveCountdown() {
            this.liveTimer && clearTimeout(this.liveTimer);
            this.liveTimer = setTimeout(() => {
                if (this.liveDuration <= 0) return void 0;
                this.liveDuration--;
                this.liveDuration > 0 ? this.setLiveCountdown() : this.$bus.$emit("countdownEnd");
            }, 1000);
        },
        setJumpTimer() {
            this.jumpTimer && clearInterval(this.jumpTimer);
            this.jumpTimer = setInterval(() => {
                if(this.jumpIndex >= this.questionList.length - 1) {
                    clearInterval(this.jumpTimer);
                    return;
                }
                this.jumpIndex = this.jumpIndex * 1 + 1;
                localStorage.setItem("jumpIndex", this.jumpIndex);
            }, 10000)
        }
    },
    watch: {
        liveState: {
            handler(newVal, oldVal) {
                if(newVal == 1) {
                    this.setLiveCountdown();
                    this.setJumpTimer();
                }else {
                    this.liveTimer && clearTimeout(this.liveTimer);
                    this.jumpTimer && clearInterval(this.jumpTimer);
                    if(newVal == 0) {
                        this.jumpIndex = 0;
                        localStorage.setItem("jumpIndex", 0);
                    }
                }
            },
            immediate: true,
        },
        liveTime: {
            handler(newVal, oldVal) {
                newVal && this.$set(this, "liveDuration", newVal.total_time.total_time);
            },
            deep: true,
            immediate: true,
        },
    },
    filters: {
        getTimeStr(time) {
            return (time + "").length <= 1 ? `0${time}` : time;
        },
        carryBit(value, liveState) {
            if (liveState != 1 && liveState != 3 && liveState != 2) return 0;
            value = value + "";
            return value.length >= 5 ? `${value.substring(0, value.length - 4)}w` : value;
        },
        liveTimeFormat(liveTime, getTime) {
            if (!liveTime) return 0 + "秒";
            const times = getTime(liveTime.total_time.total_time);
            const units = ["时", "分", "秒"];
            return times.reduce((str, item, index) => str + (item ? `${item}${units[index]}` : ""), "");
        },
    },
    mounted() {
        let index = localStorage.getItem("jumpIndex") * 1;
        if(index) {
            if(index > this.questionList.length - 1) {
                localStorage.setItem("jumpIndex", this.questionList.length - 1);
                index = localStorage.getItem("jumpIndex") * 1;
            }
            this.jumpIndex = index;
        }
        this.init();
    },
    beforeDestroy() {
        this.trainTimer && clearInterval(this.trainTimer);
        this.jumpTimer && clearInterval(this.jumpTimer);
    },
};
</script>

<style scoped lang="scss">
.live-popularity {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    padding: 15px 0;
    color: #fff;
    font-size: 18px;
    .icon {
        font-size: 22px;
        line-height: 28px;
        margin-right: 9px;
    }
    .item .hint-box,
    .item .right {
        width: 108px;
    }
    .time-box {
        display: flex;
        justify-content: space-between;
        width: 80%;
        .item {
            display: flex;
            .time {
                margin-top: 10px;
                color: #ff3e6c;
            }
            .not-start-time {
                color: #fff;
            }
        }
    }
    .popularity {
        display: flex;
        width: 80%;
        .right-popularity {
            flex: 1;
            .info-list {
                margin-top: 20px;
                .item {
                    display: flex;
                    justify-content: space-between;
                    font-size: 16px;
                    margin-bottom: 25px;
                    .num-box {
                        display: flex;
                        align-items: center;
                        margin-top: 10px;
                        font-size: 24px;
                        color: #4b45ff;
                        .rise,
                        .go-down {
                            margin-left: 15px;
                            font-size: 25px;
                            color: #ff3e6c;
                        }
                        .go-down {
                            color: #12c1c1;
                        }
                    }
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}
</style>
